.video-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.video-title {
  font-family: var(--text);
  font-size: 24px;
  color: hsl(208, 19%, 16%);
}

.video-roles {
  font-style: italic;
  font-size: 16px;
  padding: 3px;
  color: var(--lightgray);
  font-family: var(--header);
  margin-bottom: 3%;
}

.painting-container {
  position: relative;
  width: 75%;
  max-width: 1000px;
  align-self: center;
  left: 12.5%;
}

.video-container {
  position: relative;
  width: 75%;
  max-width: 1000px;
  align-self: center;
  left: 12.5%;
}

.painting-image {
  width: 100%;
  height: auto;
  align-self: center;
}

.painting-video {
  position: absolute;
  top: 8%;
  left: 27%;
  width: 46%;
  height: 45%;
  object-fit: cover;
}

.iframe {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* painting videos */
.athletica {
  position: absolute;
  top: 9.5%;
  left: 6.5%;
  width: 85%;
  height: 43%;
  object-fit: cover;
}

.bernard-one {
  position: absolute;
  top: 0%;
  left: 20.5%;
  width: 59%;
  height: 25.5%;
  object-fit: cover;
}

.bernard-two {
  position: absolute;
  top: 20.4%;
  left: 12%;
  width: 75.4%;
  height: 25.8%;
  object-fit: cover;
}

.bernard-three {
  position: absolute;
  top: 37.5%;
  left: 14.5%;
  width: 70%;
  height: 25%;
  object-fit: cover;
}

.bernard-four {
  position: absolute;
  top: 54.3%;
  left: 10.5%;
  width: 76%;
  height: 25%;
  object-fit: cover;
}

.bernard-five {
  position: absolute;
  top: 70.5%;
  left: 15%;
  width: 69%;
  height: 27%;
  object-fit: cover;
}

.bgc {
  position: absolute;
  top: 5.5%;
  left: 33.5%;
  width: 36%;
  height: 51%;
  object-fit: cover;
}

.brandon-one {
  position: absolute;
  top: 0.5%;
  left: 14.5%;
  width: 69.9%;
  height: 18%;
  object-fit: cover;
}

.brandon-two {
  position: absolute;
  top: 18.8%;
  left: 11.5%;
  width: 76%;
  height: 17%;
  object-fit: cover;
}

.brandon-three {
  position: absolute;
  top: 37.5%;
  left: 14%;
  width: 70.3%;
  height: 16%;
  object-fit: cover;
}

.brandon-four {
  position: absolute;
  top: 54.3%;
  left: 10.6%;
  width: 76%;
  height: 18.5%;
  object-fit: cover;
}

.brandon-five {
  position: absolute;
  top: 72.4%;
  left: 15%;
  width: 69%;
  height: 19%;
  object-fit: cover;
}

.camilla-and-marc {
  position: absolute;
  top: 10%;
  left: 9.5%;
  width: 80%;
  height: 45%;
  object-fit: cover;
}

.cfda-vogue {
  position: absolute;
  top: 10%;
  left: 32%;
  width: 36%;
  height: 42%;
  object-fit: cover;
}

.crack-mag {
  position: absolute;
  top: 9%;
  left: 38%;
  width: 30%;
  height: 35%;
  object-fit: cover;
}

.david-webb {
  position: absolute;
  top: 6%;
  left: 33%;
  width: 33%;
  height: 54%;
  object-fit: cover;
}

.dazed-korea {
  position: absolute;
  top: 12%;
  left: 10%;
  width: 80%;
  height: 46%;
  object-fit: cover;
}

.expedia {
  position: absolute;
  top: 7%;
  left: 7%;
  width: 86%;
  height: 44%;
  object-fit: cover;
}

.fleur-one {
  position: absolute;
  top: -2.3%;
  left: 7%;
  width: 84.5%;
  height: 19.5%;
  object-fit: cover;
}

.fleur-two {
  position: absolute;
  top: 11.1%;
  left: 10%;
  width: 79.2%;
  height: 21%;
  object-fit: cover;
}

.fleur-three {
  position: absolute;
  top: 46.3%;
  left: 15.5%;
  width: 73%;
  height: 23%;
  object-fit: cover;
}

.fleur-four {
  position: absolute;
  top: 25.6%;
  left: 16.3%;
  width: 66.3%;
  height: 26%;
  object-fit: cover;
}

.fleur-five {
  position: absolute;
  top: 62.8%;
  left: 23%;
  width: 58%;
  height: 30%;
  object-fit: cover;
}

.givenchy {
  position: absolute;
  top: 6%;
  left: 33%;
  width: 33%;
  height: 54%;
  object-fit: cover;
}

.guerlian-one {
  position: absolute;
  top: 10.5%;
  left: 18%;
  width: 63%;
  height: 25%;
  object-fit: cover;
}

.guerlian-two {
  position: absolute;
  top: 45.5%;
  left: 20%;
  width: 62%;
  height: 25%;
  object-fit: cover;
}

.kenzo-one {
  position: absolute;
  top: 66.8%;
  left: 59.3%;
  width: 35.6%;
  height: 26%;
  object-fit: cover;
}

.kenzo-two {
  position: absolute;
  top: 9.5%;
  left: 58%;
  width: 36.5%;
  height: 26.5%;
  object-fit: cover;
}

.kenzo-three {
  position: absolute;
  top: 53.5%;
  left: 7%;
  width: 40.5%;
  height: 25.5%;
  object-fit: cover;
}

.kenzo-four {
  position: absolute;
  top: 25%;
  left: 8.5%;
  width: 39.5%;
  height: 25%;
  object-fit: cover;
}

.kenzo-five {
  position: absolute;
  top: 38.5%;
  left: 57.5%;
  width: 36.5%;
  height: 26.5%;
  object-fit: cover;
}

.kenzo-six {
  position: absolute;
  top: -4.5%;
  left: 7.5%;
  width: 40.5%;
  height: 25.5%;
  object-fit: cover;
}

.lanvin-one {
  position: absolute;
  top: 4%;
  left: 14%;
  width: 26.5%;
  height: 34%;
  object-fit: cover;
}

.lanvin-two {
  position: absolute;
  top: 17%;
  left: 56%;
  width: 33%;
  height: 41.5%;
  object-fit: cover;
}

.lanvin-three {
  position: absolute;
  top: 46%;
  left: 15%;
  width: 26%;
  height: 32.5%;
  object-fit: cover;
}

.leset {
  position: absolute;
  top: 12%;
  left: 13%;
  width: 77.5%;
  height: 41%;
  object-fit: cover;
}

.lepere-one {
  position: absolute;
  top: -2%;
  left: 8%;
  width: 84%;
  height: 16%;
  object-fit: cover;
}

.lepere-two {
  position: absolute;
  top: 10.5%;
  left: 10%;
  width: 79%;
  height: 14.4%;
  object-fit: cover;
}

.lepere-three {
  position: absolute;
  top: 17.3%;
  left: 26.5%;
  width: 51.5%;
  height: 32%;
  object-fit: cover;
}

.lepere-four {
  position: absolute;
  top: 40.5%;
  left: 26%;
  width: 53%;
  height: 18%;
  object-fit: cover;
}

.lepere-five {
  position: absolute;
  top: 54.6%;
  left: 5.4%;
  width: 83.8%;
  height: 14.5%;
  object-fit: cover;
}

.lepere-six {
  position: absolute;
  top: 66.2%;
  left: 23.5%;
  width: 55%;
  height: 22%;
  object-fit: cover;
}

.malcom-one {
  position: absolute;
  top: 4%;
  left: 14%;
  width: 73%;
  height: 30%;
  object-fit: cover;
}

.malcom-two {
  position: absolute;
  top: 39.5%;
  left: 13%;
  width: 72.5%;
  height: 29%;
  object-fit: cover;
}

.marc-jacobs {
  position: absolute;
  top: 4.5%;
  left: 33.5%;
  width: 36%;
  height: 60.5%;
  object-fit: cover;
}

.mejuri {
  position: absolute;
  top: 8%;
  left: 11%;
  width: 72%;
  height: 37%;
  object-fit: cover;
}

.melitta {
  position: absolute;
  top: 4.5%;
  left: 33.5%;
  width: 29%;
  height: 50.5%;
  object-fit: cover;
}

.montell-fish {
  position: absolute;
  top: 6.6%;
  left: 6%;
  width: 86%;
  height: 48%;
  object-fit: cover;
}

.on-running-one {
  position: absolute;
  top: 4.2%;
  left: 7%;
  width: 40%;
  height: 50%;
  object-fit: cover;
}

.on-running-two {
  position: absolute;
  top: 25%;
  left: 54%;
  width: 40%;
  height: 49%;
  object-fit: cover;
}

.pyer-moss-one {
  position: absolute;
  top: 6%;
  left: 57%;
  width: 35.5%;
  height: 44.5%;
  object-fit: cover;
}

.pyer-moss-two {
  position: absolute;
  top: 7%;
  left: 6.5%;
  width: 36%;
  height: 45%;
  object-fit: cover;
}

.rabanne {
  position: absolute;
  top: 7.5%;
  left: 34%;
  width: 31%;
  height: 52%;
  object-fit: cover;
}

.schon {
  position: absolute;
  top: 8%;
  left: 25%;
  width: 50%;
  height: 46%;
  object-fit: cover;
}

.face-one {
  position: absolute;
  top: -5.2%;
  left: 6%;
  width: 40%;
  height: 26.5%;
  object-fit: cover;
}

.face-two {
  position: absolute;
  top: 9.7%;
  left: 54%;
  width: 40.5%;
  height: 26.5%;
  object-fit: cover;
}

.face-three {
  position: absolute;
  top: 24.5%;
  left: 5%;
  width: 41%;
  height: 27%;
  object-fit: cover;
}

.face-four {
  position: absolute;
  top: 39.3%;
  left: 55.5%;
  width: 38.8%;
  height: 26.5%;
  object-fit: cover;
}

.face-five {
  position: absolute;
  top: 53.4%;
  left: 6%;
  width: 39.2%;
  height: 26%;
  object-fit: cover;
}

.face-six {
  position: absolute;
  top: 67.3%;
  left: 55%;
  width: 37.7%;
  height: 26.5%;
  object-fit: cover;
}

.theory-one {
  position: absolute;
  top: 11%;
  left: 8%;
  width: 34%;
  height: 34%;
  object-fit: cover;
}

.theory-two {
  position: absolute;
  top: 10.5%;
  left: 56%;
  width: 38.5%;
  height: 35%;
  object-fit: cover;
}

.valentino-one {
  position: absolute;
  top: 69%;
  left: 10%;
  width: 32.5%;
  height: 25%;
  object-fit: cover;
}

.valentino-two {
  position: absolute;
  top: -4.4%;
  left: 6%;
  width: 40%;
  height: 25%;
  object-fit: cover;
}

.valentino-three {
  position: absolute;
  top: 53.9%;
  left: 10.5%;
  width: 33%;
  height: 18%;
  object-fit: cover;
}

.valentino-four {
  position: absolute;
  top: 23%;
  left: 5%;
  width: 38.7%;
  height: 26%;
  object-fit: cover;
}

.valentino-five {
  position: absolute;
  top: 60%;
  left: 58.5%;
  width: 30.5%;
  height: 24.5%;
  object-fit: cover;
}

.valentino-six {
  position: absolute;
  top: 36.7%;
  left: 55%;
  width: 39.5%;
  height: 26.5%;
  object-fit: cover;
}

.valentino-seven {
  position: absolute;
  top: 9.5%;
  left: 53.8%;
  width: 39.2%;
  height: 24.5%;
  object-fit: cover;
}

.victoria-one {
  position: absolute;
  top: -4.4%;
  left: 5.5%;
  width: 40.5%;
  height: 25%;
  object-fit: cover;
}

.victoria-two {
  position: absolute;
  top: 10.7%;
  left: 54%;
  width: 40.6%;
  height: 24.5%;
  object-fit: cover;
}

.victoria-three {
  position: absolute;
  top: 25.5%;
  left: 4.8%;
  width: 41%;
  height: 25%;
  object-fit: cover;
}

.victoria-four {
  position: absolute;
  top: 40.5%;
  left: 55%;
  width: 39.5%;
  height: 24%;
  object-fit: cover;
}

.victoria-five {
  position: absolute;
  top: 54.3%;
  left: 6%;
  width: 39.5%;
  height: 24%;
  object-fit: cover;
}

.victoria-six {
  position: absolute;
  top: 68.3%;
  left: 55%;
  width: 37.6%;
  height: 24.5%;
  object-fit: cover;
}

.vogue-italia {
  position: absolute;
  top: 7%;
  left: 7%;
  width: 86%;
  height: 44%;
  object-fit: cover;
}

.vogue-france {
  position: absolute;
  top: 7%;
  left: 10%;
  width: 74%;
  height: 39%;
  object-fit: cover;
}

.vogue-japan {
  position: absolute;
  top: 5%;
  left: 34%;
  width: 33%;
  height: 53%;
  object-fit: cover;
}

.wonderland {
  position: absolute;
  top: 9%;
  left: 38%;
  width: 30%;
  height: 35%;
  object-fit: cover;
}

.basic-video {
  width: 60%;
  margin-bottom: 10px;
}

@media (max-width: 430px) {
  .video-page {
    min-height: 90vh;
  }

  .video-title {
    font-size: 20px;
  }

  .painting-container {
    width: 100%;
    left: 0;
  }

  .basic-video {
    width: 90%;
    margin-bottom: 10px;
  }
}
