.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(251, 251, 255);
  transition: opacity 1s ease-in-out;
}

.intro-gif {
  width: 40%;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
  transition: opacity 2s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation: fadeOut 1.5s ease-in forwards;
  transition: opacity 1.5s ease-in;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


@media (max-width: 500px) {
  .intro-gif {
    width: 90%;
  }
}
