.landing-container {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  border-bottom: dotted 1px var(--lightgray);
}

#landing-header {
  margin-bottom: 10px;
}


.logo {
  width: 100%;
}

@media (max-width: 430px) {
  .landing-container {
    margin-bottom: 30px;
  }
  
  .logo {
    width: 100%;
  }

  #landing-header {
    font-size: 16px;
  }
}
