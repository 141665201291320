.contact-container {
    width: 100%;
    text-align: center;
}

.contact-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 30% 50px 30%;
}

.contact-link {
    text-decoration: none;
    color: var(--darkgray);

    img {
        max-width: 50px;
    }
}

.footer-image {
    padding-top: 10px;
    max-height: 150px;
}

.top-border {
    border-top: dotted 1px var(--lightgray);
}


@media (max-width: 430px) {
    .footer-image {
        width: 100%;
        max-height: 100%;
    }
}