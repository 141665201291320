@import "landing";
@import "bookshelf";
@import "about";
@import "clients";
@import "contact";
@import "video";
@import "animations";

@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

:root {
  /* color variables */
  --ivory: #F9F7E8;
  --darkgray: #212930;
  --lightgray: #3b4146;

  /* font variables */
  --header: 'Cedarville Cursive', sans-serif;
  --text: 'Playfair Display', sans-serif;
}


html,
body {
  background-color: white;
}

.box {
  border: solid 1px var(--darkgray);
  margin: 20px 15% 20px 15%;
  padding: 5px;

  .box-inner {
    border: dotted 1px var(--darkgray);
    margin: 1px;
    padding: 40px;
    min-height: 80vh;
  }
}

.header {
  font-family: var(--header);
  font-size: 24px;
  color: #212930;
}

.temporary-page {
  text-align: center;
  height: 80vh;
}

.oven {
  width: 150px;
  margin-top: 15%;
}

.cooking-text {
  font-size: 16px;
  font-family: var(--header);
}

.back-btn {
  font-family: var(--header);
  color: var(--lightgray);
}

/* CSS for the loading spinner */
.loading-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: var(--lightgray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen::after {
  content: '';
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--lightgray);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-message {
  position: absolute;
  top: 53%;
  left: 47%;
  font-size: 14px;
  font-family: var(--header);
  color: var(--lightgray);
}

@media (max-width: 430px) {
  .box {
    margin: 20px 2.5% 20px 2.5%;

    .box-inner {
      padding: 5px;
    }
  }

  .header {
    font-size: 20px;
  }

  .oven {
    width: 150px;
    margin-top: 60%;
  }

  .loading-message {
    left: 40%;
  }
}