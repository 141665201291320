.about-container {
  width: 100%;
  text-align: center;
  border-bottom: dotted 1px var(--lightgray);
  margin-bottom: 20px;
}

.about-bio {
  font-family: var(--text);
  line-height: 2;
  margin: 0 20% 0 20%;
}

.border-image {
  max-height: 100px;
}

.co-description {
  font-family: var(--text);
  font-size: 14px;
  margin-bottom: 40px;
}

@media (max-width: 430px) {
  .about-container {
    margin-bottom: 30px;
  }

  .about-bio {
    margin: 0 2% 0 2%;
    font-size: 12px;
  }

  .co-description {
    font-size: 12px;
    margin-bottom: 30px
  }

  .border-image {
    max-height: 150px;
  }
}
