.clients-container {
    width: 100%;
    margin-bottom: 80px;
}

#clients-header {
    text-align: center;
    
}

.clients-list {
    line-height: 2;
    margin-left: 15%;
}

.client-list-item {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: var(--lightgray)
}

.client-title {
    margin-right: 5px;
    font-family: var(--text);
}

.client-description {
    font-style: italic;
    font-size: 14px;
    padding: 3px;
    color: var(--lightgray);
    font-family: var(--header);
}

.clients-message {
    text-align: center;
    font-family: var(--text);
    font-weight: 600;
    color: var(--lightgray);
    font-size: 12px;
    margin-bottom: 20px;
}


@media (max-width: 430px) {
    #longer-client-name {
        line-height: 1;
    }

    .clients-list {
        justify-content: space-between;
        margin-left: 15px;
        font-size: 14px;
    }

    .client-list-item {
        flex-direction: column;
        margin-bottom: 5px;
    }
  }
  